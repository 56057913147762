<template>
  <div>
    <v-card>
      <v-card-title> Relatórios das Ordem de Compra </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="getConstructionsSelect"
              outlined
              label="Obra(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.constructions"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getProvidersSelect"
              outlined
              label="Fornecedor(es)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.providers"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getOrderItemsSelect"
              outlined
              label="Material(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.items"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="loadReport">Buscar Relátorio</v-btn>
      </v-card-actions>
    </v-card>
    <DialogHistoty />
    <v-card class="mt-2">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">OC</th>
            <th class="text-left">ERP</th>
            <th class="text-left">Nome</th>
            <th class="text-left">Fornecedor</th>
            <th class="text-left">Obra</th>
            <th class="text-left">Descrição</th>
            <th class="text-left">Quantidade</th>
            <th class="text-left">Cadastrado em</th>
            <th class="text-left">Atualizado em</th>
            <th class="text-left">Ver Histórico</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in getOrdersReport" :key="index">
            <td>
              {{ item.buyOrder }}
            </td>
            <td>
              {{ item.erpIdentifier }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ getProviderNameById(item.providerId) }}
            </td>
            <td>
              {{ getConstructionNameById(item.constructionId) }}
            </td>
            <td>
              {{ item.description }}
            </td>
            <td>
              <div v-for="it in item.items" :key="it.id">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-circular
                      :rotate="90"
                      :size="70"
                      :width="10"
                      :value="percent(it)"
                      :color="colorProgress(it)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ percent(it) }} %
                    </v-progress-circular>
                  </template>
                  <div>Nome: {{ it.name }}</div>
                  <div>Inicial: {{ it.amountStart }}</div>
                  <div>Carregado: {{ it.amountStart - reduceValue(it) }}</div>
                  <div>Saldo: {{ reduceValue(it) }}</div>
                  <div>Coletado: {{ colectedCount(it) }} X</div>
                </v-tooltip>
              </div>
            </td>
            <td>
              {{ new Date(item.createdAt).toLocaleString() }}
            </td>
            <td>
              {{ new Date(item.updatedAt).toLocaleString() }}
            </td>
            <td>
              <v-btn
                icon
                @click="$store.dispatch('dialogRelatorioHistoryOpen', item)"
                ><v-icon>mdi-eye</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogHistoty from "../components/Relatorios/DialogHistory.vue";
export default {
  name: "RelOrdem",
  components: {
    DialogHistoty,
  },
  data: () => ({
    report: {
      dirI: "",
      items: [],
    },
  }),
  methods: {
    loadReport() {
      this.$store.dispatch("getReportBO", this.report);
      this.$store.dispatch("setOrderReportFilter", this.report);
    },
    showIcon(item) {
      if (item.loadedDate && item.finishedDate && item.uniqueId) {
        return true;
      } else {
        return false;
      }
    },
    reduceValue(item) {
      let inital = item.amountStart;
      item.history.forEach((element) => {
        if (element.colectedAmount) {
          inital -= element.colectedAmount;
        }
      });
      return inital;
    },
    colectedCount(item) {
      let colected = 0;
      item.history.forEach((element) => {
        if (element.finishedDate) {
          colected++;
        }
      });
      return colected;
    },
    percent(item) {
      let pct = (this.reduceValue(item) / item.amountStart) * 100;
      return pct > 0 ? pct.toFixed(1) : 0;
    },
    colorProgress(item) {
      let pct = this.percent(item);
      if (pct > 0 && pct < 50) {
        return "orange";
      } else if (pct > 0 && pct < 20) {
        return "red";
      } else {
        return "green";
      }
    },
  },
  computed: {
    ...mapGetters([
      "getBOReport",
      "getConstructions",
      "getOrders",
      "getConstructionNameById",
      "getProviderNameById",
      "getConstructionsSelect",
      "getProvidersSelect",
      "getUserSelect",
      "getBOtoUsers",
      "getItemOrdemById",
      "getOrderById",
      "getUserNameById",
      "getVehiclesSelect",
      "getTraceableById",
      "getUserById",
      "getVehicleById",
      "getOrderReportFilter",
      "getOrdemSelect",
      "getOrdersReport",
      "getOrderItemsSelect",
    ]),
  },
  mounted() {
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadProviders");
    this.$store.dispatch("loadConstructions");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadBOtoUser");
    this.$store.dispatch("loadVehicles");
    this.$store.dispatch("loadOrdersItems");
    this.report = this.getOrderReportFilter;
  },
};
</script>

<style>
</style>