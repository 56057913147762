<template>
  <v-dialog
    v-model="getDialogRelatorioHistoryState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Histórico da Ordem :
          {{ getDialogRelatorioHistory.data.name }}</v-toolbar-title
        >
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn dark @click="close"> Fechar </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-card class="ma-0 mt-1">
          <v-card-title>
            Obra:
            {{
              getConstructionNameById(
                getDialogRelatorioHistory.data.constructionId
              )
            }}
          </v-card-title>

          <v-card-subtitle
            >Fornecedor:
            {{ getProviderNameById(getDialogRelatorioHistory.data.providerId) }}
          </v-card-subtitle>
          <v-card-text>
            <!-- for dos itens -->
            <div
              v-for="item in getDialogRelatorioHistory.data.items"
              :key="item.id"
            >
              <v-card class="mt-1" elevation="0" outlined>
                <v-card-title>
                  <span>{{ item.name }}</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mx-1"
                    color="success"
                    v-show="item.history.length"
                    @click="exportExcell(item.history)"
                  >
                    <v-icon small class="mr-1">mdi-microsoft-excel</v-icon>
                    Gerar excell
                  </v-btn>
                  <!-- pdf -->
                  <v-btn
                    color="success"
                    v-show="item.history.length"
                    @click="exportPdf(item.history)"
                  >
                    <v-icon small class="mr-1">mdi-file-pdf-box</v-icon>
                    Gerar PDF
                  </v-btn>
                </v-card-title>
                <v-card-subtitle>
                  <v-row>
                    <v-col
                      >Quantidade Inicial do Item: {{ item.amountStart }}</v-col
                    >
                    <v-col
                      >Quantidade Carregada:
                      {{ item.amountStart - reduceValue(item) }}
                    </v-col>
                    <v-col>Saldo: {{ reduceValue(item) }}</v-col>
                    <v-col>Coletado x: {{ colectedCount(item) }}</v-col>
                  </v-row>
                </v-card-subtitle>
                <v-card-text>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-left">ID</th>
                        <th class="text-left">Enviado Por</th>
                        <th class="text-left">Direcionado em</th>
                        <th class="text-left">Descrição</th>
                        <th class="text-left">Motorista</th>
                        <th class="text-left">Placa</th>
                        <th class="text-left">Carregado</th>
                        <th class="text-left">Carregado em</th>
                        <th class="text-left">Finalizado Por</th>
                        <th class="text-left">Finalizado em</th>
                        <th class="text-left">Desc. Finalização</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- for do historico do item -->
                      <tr v-for="(itemh, index) in item.history" :key="index">
                        <td>{{ itemh.id }}</td>
                        <td>{{ getUserNameById(itemh.createUserId) }}</td>
                        <td>
                          {{
                            itemh.createdAt
                              ? new Date(itemh.createdAt).toLocaleString()
                              : ""
                          }}
                        </td>
                        <td>{{ itemh.description }}</td>
                        <td>{{ getUserNameById(itemh.userId) }}</td>
                        <td>{{ itemh.plate }}</td>
                        <td>{{ itemh.colectedAmount }}</td>
                        <td>
                          {{
                            itemh.loadedDate
                              ? new Date(itemh.loadedDate).toLocaleString()
                              : "Não Carregado"
                          }}
                        </td>
                        <td>{{ getUserNameById(itemh.finishedByUserId) }}</td>
                        <td>
                          {{
                            itemh.finishedDate
                              ? new Date(itemh.finishedDate).toLocaleString()
                              : "Não Finalizado"
                          }}
                        </td>
                        <td>{{ itemh.finishedComment }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </div>

            <!-- {{ getDialogRelatorioHistory.data }} -->
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { generate } from "@/utils/excell.js";
import { orderReportHistory } from "@/utils/pdf.js";
export default {
  name: "DialogReportHistory",
  props: ["item"],
  data() {
    return {
      dialog: false,
      resume: {
        distance: 0,
        averageSpeed: 0,
        maxSpeed: 0,
        difTime: "0h 0m",
      },
      map: null,
      route: [],
      markers: [],
      polyline: null,
    };
  },
  computed: {
    ...mapGetters([
      "getDialogRelatorioHistory",
      "getDialogRelatorioHistoryState",
      "getConstructionNameById",
      "getProviderNameById",
      "getUserNameById",
    ]),
  },
  methods: {
    close() {
      this.$store.dispatch("dialogRelatorioHistoryClose");
    },
    reduceValue(item) {
      let inital = item.amountStart;
      item.history.forEach((element) => {
        if (element.colectedAmount) {
          inital -= element.colectedAmount;
        }
      });
      return inital;
    },
    colectedCount(item) {
      let colected = 0;
      item.history.forEach((element) => {
        if (element.finishedDate) {
          colected++;
        }
      });
      return colected;
    },
    exportExcell(history) {
      let columns = [
        { header: "ID", key: "id", width: 10 },
        { header: "Enviado por", key: "createUser", width: 32 },
        { header: "Direcionado", key: "createdAt", width: 20 },
        { header: "Descrição", key: "description", width: 32 },
        { header: "Motorista", key: "driverUser", width: 32 },
        { header: "Placa", key: "plate", width: 20 },
        { header: "Carregado", key: "colectedAmount", width: 20 },
        { header: "Carregado em", key: "loadedDate", width: 20 },
        { header: "Finalizado por", key: "finishedByUser", width: 32 },
        { header: "Finalizado em", key: "finishedDate", width: 20 },
        { header: "Desc. finalização", key: "finishedComment", width: 32 },
      ];

      // gera as linhas para o excel
      let rows = this.generateRows(history);
      // nome da planilha
      let nameSheet = "STV Relatório ordens usuário";
      // corpo do excel
      let excelBody = [{ columns, rows, nameSheet }];
      // gera o excel e faz o download
      generate(excelBody).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Relatório Ordens_Mot.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    exportPdf(history) {
      // gera as linhas para o excel
      let rows = this.generateRows(history);
      // nome da planilha
      let title = "STV Relatório de ordem";
      // gera o excel e faz o download
      orderReportHistory(rows, title, null, title.replace(" ", "_"));
    },
    generateRows(history) {
      let rows = [...history];

      rows.forEach((e) => {
        e.createUser = this.getUserNameById(e.createUserId);
        e.createdAt = e.createdAt ? new Date(e.createdAt).toLocaleString() : "";
        e.driverUser = this.getUserNameById(e.userId);
        e.loadedDate = e.loadedDate ? new Date(e.loadedDate).toLocaleString() : "";
        e.finishedByUser = this.getUserNameById(e.finishedByUserId);
        e.finishedDate = e.finishedDate ? new Date(e.finishedDate).toLocaleString() : "";
      });
      return rows;
    },
  },
  watch: {},
};
</script>

<style>
</style>